import React, { useEffect, useState } from "react";
import { builder, BuilderComponent } from "@builder.io/react";
import { RegisterGlobalBannerSection } from "../BuilderWrapperComponents/GlobalBannerSectionBC";
import { logger } from "@utils/logger";
import { Cookie } from "@utils/Cookie";

const GABannerSection: React.FC = () => {
  const [bannerContent, setBannerContent] = useState({});

  useEffect(() => {
    if (!window) return;
    builder
      .get("global-announcement-banner", {
        userAttributes: {
          urlPath: window.location.pathname,
          customer: Boolean(Cookie.getCookie("sp_last_access")),
        },
        options: {
          cachebust: true,
        },
      })
      .toPromise()
      .then(content => setBannerContent(content))
      .catch(err => logger(err.message));
  }, []);
  return (
    <BuilderComponent
      content={bannerContent}
      model="global-announcement-banner"
    />
  );
};

RegisterGlobalBannerSection();

export default GABannerSection;
