import * as React from "react";
import { Builder } from "@builder.io/react";
import loadVideo from "../../../assets/util-scripts/load-video";

import styles from "./CtaButtonPlayVideo.module.scss";

type CtaButtonPlayVideoType = {
  wistiaId: string;
};

const CtaButtonPlayVideo: React.FC<CtaButtonPlayVideoType> = ({ wistiaId }) => {
  const handleClick = () => {
    loadVideo(wistiaId);
  };

  return (
    <div className={styles.btnPlayVideo} onClick={handleClick}>
      <svg className={styles.icon}>
        <use href="/icons/play-hollow.svg#icon"></use>
      </svg>
    </div>
  );
};

export default CtaButtonPlayVideo;

export const RegisterCtaButtonPlayVideo = () => {
  Builder.registerComponent(CtaButtonPlayVideo, {
    name: "cta-button-playvideo",
    inputs: [
      {
        name: "wistiaId",
        type: "string",
      },
    ],
  });
};
