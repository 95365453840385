import React, { useState } from "react";
import EmailOnlyFormProvider from "../../shared/EmailOnlyFormProvider";
import { Builder } from "@builder.io/react";
import { formInitialCtx } from "../../../contexts/FormOnlyEmail.context";
import { EmailOnlyFormProps, IterableDataType } from "@types";
import { mxpPrimaryCtaClicked } from "../../../utils/mixpanelEvents/mixpanelEvents";
import { iterableSubmit } from "../../../utils/iterableSubmit";
import { getFormattedTime } from "../../../assets/util-scripts/helpers";
import { useSignUpFormNavigate } from "@hooks/useSignUpFormNavigate";
import { leadCapture } from "@utils/segment/leadCapture";

type EmailOnlyFormBCType = {
  withArrow: boolean;
  buttonText: string;
  placeholder: string;
  signUpLink: string;
};

export const EmailOnlyFormBC: React.FC<EmailOnlyFormBCType> = ({
  buttonText,
  placeholder,
  signUpLink,
}) => {
  const [email, setEmail] = useState<string>("");
  const signUpFormNavigate = useSignUpFormNavigate();

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
  };

  const submitEmailData: IterableDataType = {
    email,
    SIGN_UP_SOURCE: "Website - Sign Up CTA",
    startTrialFlowAt: getFormattedTime(),
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    window.analytics.identify({
      account_status: "lead",
      email,
    });

    const analyticsData = {
      text: buttonText,
      email,
    };

    leadCapture(analyticsData);

    iterableSubmit(submitEmailData);
    mxpPrimaryCtaClicked(signUpLink, buttonText, {
      email,
      withEmailField: true,
    });
    signUpFormNavigate(email, signUpLink);
  };

  const formCtx: EmailOnlyFormProps = {
    ...formInitialCtx,
    email,
    withArrow: true,
    buttonText,
    handleChange,
    handleSubmit,
    placeholder,
  };

  return <EmailOnlyFormProvider ctxInitialVal={formCtx} withDropdown />;
};

export const RegisterEmailOnlyForm = () => {
  Builder.registerComponent(EmailOnlyFormBC, {
    name: "cta-emailcapture",
    inputs: [
      {
        name: "buttonText",
        type: "string",
      },
      {
        name: "placeholder",
        type: "string",
      },
      {
        name: "signUpLink",
        type: "string",
      },
    ],
  });
};
