import React from "react";

import styles from "./ButtonsGroup.module.scss";

type ButtonsGroupType = {
  children: React.ReactNode;
  className?: string;
};

const ButtonsGroup = ({ children }: ButtonsGroupType) => {
  return (
    <div className={styles.buttonsGroup} data-testid="buttons-group">
      {children}
    </div>
  );
};

export default ButtonsGroup;
