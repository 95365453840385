import React from "react";
import { BuilderComponent } from "@builder.io/react";

import { handleContentLoaded } from "../../utils/builderContentLoaded";

type HomeHeroContainerType = {
  heroContent: Queries.builder_HomeHeroSection["content"];
};

const HomeHeroContainer: React.FC<HomeHeroContainerType> = ({
  heroContent,
}) => (
  <BuilderComponent
    content={heroContent}
    contentLoaded={handleContentLoaded}
    model="home-hero-section"
  />
);

export default HomeHeroContainer;
