import * as React from "react";
import { Link } from "gatsby";
import RightArrow from "../../SVGIcons/RightArrow/RightArrow";

import styles from "./LinkWithArrow.module.scss";

type WhiteLinkWithArrowProps = {
  link: string;
  linkCopy: string;
  isExternal?: boolean;
};

const WhiteLinkWithArrow: React.FC<WhiteLinkWithArrowProps> = ({
  link,
  linkCopy,
  isExternal,
}) => (
  <p data-testid="P:WhiteLinkWithArrow">
    {isExternal ? (
      <a
        data-testid="a:WhiteLinkWithArrow"
        href={link}
        className={styles.link}
        target={"_blank"}
        rel="noreferrer"
      >
        <span style={{ marginRight: "4px" }}>{linkCopy}</span>
        <RightArrow cls={styles.icon} />
      </a>
    ) : (
      <Link
        data-testid="a:WhiteLinkWithArrow"
        to={link}
        className={styles.link}
      >
        <span style={{ marginRight: "4px" }}>{linkCopy}</span>
        <RightArrow cls={styles.icon} />
      </Link>
    )}
  </p>
);

export default WhiteLinkWithArrow;
