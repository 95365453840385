import * as React from "react";
import Reviews from "../../../components/Reviews/Reviews";
import { Builder } from "@builder.io/react";

type ReviewsBCPropsType = {};

const ReviewsBC: React.FC<ReviewsBCPropsType> = props => (
  <Reviews isBuilderComponent={true} />
);

export default ReviewsBC;

export const RegisterReviews = () => {
  Builder.registerComponent(ReviewsBC, {
    name: "socialproof-hipaa-stars",
  });
};
