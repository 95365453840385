import React from "react";
import { Link } from "gatsby";
import MobileMenuBtn from "../MobileMenuBtn";
import styles from "./LogoContainer.module.scss";
import useIsStickyNavStore from "../../../../sp-state/useIsStickyNavStore";
import TrialBtnLink from "../../../../components/Menus/MainNavMenu/sharedComponents/TrialBtnLink";

const LogoContainer: React.FC = () => {
  const isSticky = useIsStickyNavStore(state => state.isSticky);
  const isStickyOpen = useIsStickyNavStore(state => state.isStickyOpen);

  const handleLogoClick = () => {
    window.trackMixpanelEvent("user: Logo clicked");
  };

  return (
    <div className={`${styles.logoContainer} ${isSticky && styles.isSticky}`}>
      <Link to="/" onClick={handleLogoClick} className={styles.logo}>
        {isSticky ? (
          <>
            <div className={`${styles.logoMobile} hide-for-large`}></div>
            <svg className={`${styles.logo} hide-from-large`}>
              <use href="/icons/sp-logo.svg#sp-logo-forest"></use>
            </svg>
          </>
        ) : (
          <svg className={styles.logo}>
            <use href="/icons/sp-logo.svg#sp-logo-forest"></use>
          </svg>
        )}
      </Link>

      <div className={styles.trialBtnContainer}>
        {isSticky && !isStickyOpen && (
          <TrialBtnLink btnText="Start For Free" btnPath="/sign-up" />
        )}
        <MobileMenuBtn />
      </div>
    </div>
  );
};

export default LogoContainer;
