import * as React from "react";
import { Builder } from "@builder.io/react";
import { PrimaryButtonWithArrow } from "../../Buttons";
import { marketingCtaClicked } from "@utils/segment/marketingCtaClicked";
type PrimaryBtnWithArrowBCPropsType = {
  buttonText: string;
  buttonLink: string;
  ctaLocation: string;
};

const PrimaryBtnWithArrowBC: React.FC<PrimaryBtnWithArrowBCPropsType> = ({
  buttonText,
  buttonLink,
}) => {
  const handleClick: React.MouseEventHandler = e => {
    marketingCtaClicked({
      text: e.currentTarget.textContent as string,
      url: window.location.pathname,
      target: buttonLink,
    });
  };

  return (
    <PrimaryButtonWithArrow
      buttonText={buttonText}
      buttonLink={buttonLink}
      onClickHandler={handleClick}
    />
  );
};

export default PrimaryBtnWithArrowBC;

export const RegisterPrimaryBtnWithArrow = () => {
  Builder.registerComponent(PrimaryBtnWithArrowBC, {
    name: "cta-button-arrow",
    inputs: [
      {
        name: "buttonText",
        type: "string",
      },
      {
        name: "buttonLink",
        type: "string",
      },
    ],
  });
};
