import * as React from "react";
import { Builder } from "@builder.io/react";

import { Button } from "../../Buttons";
import { mxpPrimaryCtaClicked } from "../../../utils/mixpanelEvents/mixpanelEvents";
import { marketingCtaClicked } from "@utils/segment/marketingCtaClicked";

type PrimaryBtnBcPropsType = {
  buttonText: string;
  buttonLink: string;
  variant: string;
  ctaLocation: string;
};

type VariantMapKeysType =
  | "primary-salmon"
  | "primary-ocean"
  | "ghost-salmon"
  | "ghost-ocean";

const PrimaryBtnBc: React.FC<PrimaryBtnBcPropsType> = ({
  buttonLink,
  buttonText,
  variant,
}) => {
  const handleOnClick = (
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>
  ) => {
    mxpPrimaryCtaClicked(
      (e.target as HTMLAnchorElement).href,
      (e.target as HTMLAnchorElement).innerText
    );

    marketingCtaClicked({
      text: e.currentTarget.textContent as string,
      url: window.location.pathname,
      target: buttonLink,
    });
  };

  const variantMap = {
    "primary-salmon": "primary-red",
    "primary-ocean": "primary-blue",
    "ghost-salmon": "hollow-red",
    "ghost-ocean": "hollow-blue",
  } as const;

  type VariantMapKey = typeof variantMap[keyof typeof variantMap];

  return (
    <Button
      buttonType="link"
      buttonText={buttonText}
      buttonLink={buttonLink}
      onClick={handleOnClick}
      variant={variantMap[variant as VariantMapKeysType] as VariantMapKey}
    />
  );
};

export default PrimaryBtnBc;

export const RegisterPrimaryBtn = () => {
  Builder.registerComponent(PrimaryBtnBc, {
    name: "cta-button",
    inputs: [
      {
        name: "buttonText",
        type: "string",
      },
      {
        name: "buttonLink",
        type: "string",
      },
      {
        name: "variant",
        type: "string",
        defaultValue: "hollow-red",
        enum: [
          "primary-salmon",
          "primary-ocean",
          "ghost-salmon",
          "ghost-ocean",
        ],
      },
    ],
  });
};
